import {Injectable} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {UtilsService} from './utils.service';
import {CookieService} from 'ngx-cookie-service';
import {ConnectedUser} from '../interfaces/connected-user';
import {User} from '../interfaces/user';
import {Prereservation} from '../interfaces/prereservation';
import {Doctor} from '../interfaces/doctor';
import {Location} from '../interfaces/location';
import {Activity} from '../interfaces/activity';
import {Reservation} from '../interfaces/reservation';

@Injectable()
export class StorageService {

  public managingUsersLimit = 10;
  private shortCache = {};
  private verificationModalShown = 0;
  public mainUser;

  constructor(private sessionSt: SessionStorageService,
              private localSt: LocalStorageService,
              private cookie: CookieService,
              private utils: UtilsService) {
  }

  setAskNextSession(){
    this.sessionSt.store('askNextSession', true);
  }

  getAskNextSession(){
    return this.sessionSt.retrieve('askNextSession');
  }

  setShortCache(key, value) {
    this.shortCache[key] = value;
  }

  getShortCache(key) {
    if (this.shortCache[key]) {
      return this.shortCache[key];
    }
    return null;
  }

  setUserGUID(): void {
    this.sessionSt.store('userGUID', this.utils.generateGuid());
  };

  getUserGUID(): string {
    return this.sessionSt.retrieve('userGUID');
  };

  setTokenData(rawTokenData) {
    const tokenData = this.utils.toCamelCaseObject(rawTokenData);
    this.sessionSt.store('tokenData', tokenData);

    return tokenData;
  };

  getTokenData(): { [s: string]: string } {
    return this.sessionSt.retrieve('tokenData');
  };

  getUserData(): User {
    return this.sessionSt.retrieve('userData');
  };

  setUserData(token: { [s: string]: string }): void {
    this.sessionSt.store('userData', this.utils.parseUserData(token));
  };

  getUserFullData(): User {
    return this.sessionSt.retrieve('userFullData');
  };

  setUserFullData(user: User): void {
    this.sessionSt.store('userFullData', user);
  };

  getMainUser(): { [s: string]: string } {
    let tokenData = this.localSt.retrieve('mainUser');
    this.mainUser = this.utils.userListCleaner(tokenData);

    return this.mainUser;
  };

  setMainUser(rawTokenData) {
    let tokenData = this.utils.toCamelCaseObject(rawTokenData);
    this.localSt.store('mainUser', tokenData);
    this.mainUser = this.utils.userListCleaner(tokenData);

    return this.mainUser;
  };

  getConnectedAccounts(): object {
    return this.sessionSt.retrieve('connectedAccounts');
  };

  setConnectedAccounts(connectedAccounts: ConnectedUser[]): void {
    this.sessionSt.store('connectedAccounts', connectedAccounts);
  };

  getSelectedConnectedAccount(): object {
    return this.sessionSt.retrieve('selectedConnectedAccount');
  };

  setSelectedConnectedAccount(selectedConnectedAccount: ConnectedUser): void {
    this.sessionSt.store('selectedConnectedAccount', selectedConnectedAccount);
  };

  clearSelectedConnectedAccount(selectedConnectedAccount: ConnectedUser): void {
    this.sessionSt.clear('selectedConnectedAccount');
  };

  addKzzToCookies(kzz: number): void {
    const expireDate = new Date();
    expireDate.setHours(expireDate.getHours() + 24);
    this.cookie.set('doZdravnika-kzz', kzz.toString(), expireDate);
  };

  setPrereservation(prereservation: Prereservation): void {
    this.sessionSt.store('prereservation', prereservation);
  };

  getPrereservation(): Prereservation {
    return this.sessionSt.retrieve('prereservation');
  };

  clearPrereservation(): void {
    this.sessionSt.clear('prereservation');
  };

  setReservation(reservation: Reservation): void {
    this.sessionSt.store('reservation', reservation);
  };

  getReservation(): Reservation {
    return this.sessionSt.retrieve('reservation');
  };

  clearReservation(): void {
    this.sessionSt.clear('reservation');
  };

  setSelectedDoctor(doctor: Doctor): void {
    this.sessionSt.store('selectedDoctor', doctor);
  };

  getSelectedDoctor(): Doctor {
    return this.sessionSt.retrieve('selectedDoctor');
  };

  clearSelectedDoctor(): void {
    this.sessionSt.clear('selectedDoctor');
  };

  setSelectedLocation(location: Location): void {
    this.sessionSt.store('selectedLocation', location);
  };

  getSelectedLocation(): Location {
    return this.sessionSt.retrieve('selectedLocation');
  };

  clearSelectedLocation(): void {
    this.sessionSt.clear('selectedLocation');
  };

  setSelectedActivity(activity: Activity): void {
    this.sessionSt.store('selectedActivity', activity);
  };

  getSelectedActivity(): Activity {
    return this.sessionSt.retrieve('selectedActivity');
  };

  clearSelectedActivity(): void {
    this.sessionSt.clear('selectedActivity');
  };

  setAppId(appId: string): void {
    this.sessionSt.store('appId', appId);
  };

  getAppId(): string {
    return this.sessionSt.retrieve('appId');
  };

  clearAppId(): void {
    this.sessionSt.clear('appId');
  };

  clearUserGUID(): void {
    this.sessionSt.clear('userGUID');
  };

  clearSessionData(): void {
    this.sessionSt.clear('userData');
  };

  clearTokenData(): void {
    this.sessionSt.clear('tokenData');
  };

  clearUserFullData(): void {
    this.sessionSt.clear('userFullData');
  };

  clearMainUserData(): void {
    this.localSt.clear('mainUser');
  };

  clearAskNextSession(): void {
    this.sessionSt.clear('askNextSession');
  }

  clearAllScheduleData(): void {
    this.clearSelectedDoctor();
    this.clearSelectedActivity();
    this.clearPrereservation();
    this.clearAppId();
  };

  clearAllUserData(): void {
    this.clearUserGUID();
    this.clearSessionData();
    this.clearUserFullData();
    this.clearTokenData();
    this.clearMainUserData();
    this.clearAskNextSession();
  };

  clearAll(): void {
    this.clearAllUserData();
    this.clearAllScheduleData();
  };

  verificationModalSet(value) {
    this.verificationModalShown = value;
  }

  verificationModalGet() {
    return this.verificationModalShown;
  }

}
