import { Component, OnDestroy, OnInit } from '@angular/core';
import {ToasterConfig} from 'angular2-toaster';
import {ActivatedRoute, Router} from '@angular/router';

import * as urlParse from 'url-parse';
import {LoginService} from './modules/shared/login/login.service';
import {SocketService} from './shared-services/socket.service';
import * as _ from 'lodash';
import {LayoutService} from './shared-services/layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public toasterConfig: ToasterConfig =
    new ToasterConfig({
      showCloseButton: true,
      timeout: 10000,
      animation: 'flyRight',
      // positionClass: 'toast-top-center',
      // toasterPosition: cmnUtilsSvc.isMobile() ? 'toast-top-center' : 'toast-top-right',
    });

  public mainNavbarToggled: boolean = false;
  public mainNavbarSubscription$;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private socket: SocketService,
              private layout: LayoutService,
  ) {
  }

  ngOnInit() {

    if (this.loginService.isAuthenticated()) {
      this.socket.initSocket();
    }

    this.mainNavbarSubscription$ = this.layout.navbarToggle$.subscribe((toggle) => {
      this.mainNavbarToggled = toggle;
    })

    /*
    this.route.queryParams.subscribe((params: any) => {
      if (!_.isUndefined(params.doctorId) && !_.isUndefined(params.locations)) {
        if (!this.findChild('activate/:id', this.route)) {
          const redirectParams = JSON.stringify(params);
          this.router.navigate(['.'], {
            queryParams: {
              redirect: '/#!/app/reservation/schedule/:doctorId?locations',
              redirectParams: redirectParams,
            }
          });
        }
      }
    });
    */

    if(window.location.href.indexOf('www.') != -1)
      window.location.href = window.location.href.replace(/www./g, "");


    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment.slice(0, 2) === '!/') {
        fragment = fragment.slice(1);
        // console.log('Našel fragment, ki izgleda kot star URL', fragment)
        const parsedFragment = urlParse(fragment, true);

        if (parsedFragment.query.redirect === 'tvw' || parsedFragment.query.redirectType === 'tvw') {
          this.router.navigate(['/'], {queryParams: parsedFragment.query});
          return;
        }

        if (parsedFragment.pathname.slice(0, 5) === '/auth') {
          if (parsedFragment.pathname.indexOf('/auth/login') !== -1) {
            this.router.navigate(['/'], {queryParams: parsedFragment.query});
            return;
          }
        }

        if (parsedFragment.pathname.slice(0, 8) === '/account') {
          if (parsedFragment.pathname.indexOf('/account/register') !== -1) {
            this.router.navigate(['/account/register'], {queryParams: parsedFragment.query});
            return;
          }
          if (parsedFragment.pathname.indexOf('/account/password') !== -1) {
            this.router.navigate(['/account/password'], {queryParams: parsedFragment.query});
            return;
          }
          if (parsedFragment.pathname.indexOf('/account/activation-required') !== -1) {
            this.router.navigate(['/account/activation-required'], {queryParams: parsedFragment.query});
            return;
          }
          if (parsedFragment.pathname.indexOf('/account/activate') !== -1) {

            const path = parsedFragment.pathname;
            const token = path.replace('/account/activate/', '');

            this.router.navigate(['/account/activate/' + token], {queryParams: parsedFragment.query});
            return;
          }
          if (parsedFragment.pathname.indexOf('/account/recover-password') !== -1) {

            const path = parsedFragment.pathname;
            const token = path.replace('/account/recover-password/', '');

            this.router.navigate(['/account/recover-password/' + token], {queryParams: parsedFragment.query});
            return;
          }
        }

        if (parsedFragment.pathname.slice(0, 4) === '/app') {
          if (parsedFragment.pathname.indexOf('/app/connected-accounts') !== -1) {
            this.router.navigate(['g/connected-accounts'], {queryParams: parsedFragment.query});
            return;
          }

          if (parsedFragment.pathname.indexOf('/app/profile/') !== -1) {
            this.router.navigate(['g/account'], {queryParams: parsedFragment.query});
            return;
          }
        }

        if (parsedFragment.pathname.slice(0, 4) === '/app') {
          if (parsedFragment.pathname.indexOf('/app/home') !== -1) {
            this.router.navigate(['g/dashboard'], {queryParams: parsedFragment.query});
            return;
          }

          const doctorId = parsedFragment.pathname.slice(parsedFragment.pathname.lastIndexOf('/') + 1);
          const query = {
            doctor: doctorId,
            location: parsedFragment.query.locations,
            ...parsedFragment.query,
          };

          if (parsedFragment.pathname.indexOf('/app/reservation/schedule/') !== -1) {
            this.router.navigate(['/reservation-create'], {queryParams: query});
            return;
          }
        }

        // if (parsedFragment.pathname.indexOf('/messages/details/') !== -1) {
        //   this.router.navigate(['/messages/details'], {queryParams: parsedFragment.query});
        //   console.log(fragment)
        //   return
        // }
        //#!/app/reservation/schedule/52001?locations=00130&activity=SPLETNAR
        //#!/messages/details/199

        // window.location.href = environment.v1Url + '/#!' + fragment;

      }
    });
  }

  ngOnDestroy(): void {
    this.mainNavbarSubscription$.unsubscribe();
  }

  findChild(needle, route) {
    if (route && route.routeConfig && route.routeConfig.path === 'activate/:id') {
      return true;
    } else if (route.firstChild) {
      return this.findChild(needle, route.firstChild);
    } else {
      return false;
    }
  }
}
