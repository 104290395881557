/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./login-error-modal.component";
var styles_LoginErrorModalComponent = [];
var RenderType_LoginErrorModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginErrorModalComponent, data: {} });
export { RenderType_LoginErrorModalComponent as RenderType_LoginErrorModalComponent };
function View_LoginErrorModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["Elektronski naslov (", ") in geslo se ne ujemata."])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Ste pozabili svoje geslo?"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [["routerLink", "/account/password"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Zahtevajte novo geslo"]))], function (_ck, _v) { var currVal_3 = "/account/password"; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.email; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 8).target; var currVal_2 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_1, currVal_2); }); }
function View_LoginErrorModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["Elektronski naslov (", ") \u0161e ni registriran."])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u010Ce ste se ravnokar registrirali, je potrebno pred prijavo v spletno re\u0161itev doZdravnika potrditi elektronski naslov in aktivirati va\u0161 uporabni\u0161ki ra\u010Dun. "])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [["routerLink", "/help"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Preberi pomo\u010D"])), (_l()(), i0.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "a", [["routerLink", "/account/register"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ustvarite si nov ra\u010Dun"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "/help"; _ck(_v, 8, 0, currVal_3); var currVal_6 = _co.queryParams; var currVal_7 = "/account/register"; _ck(_v, 14, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.email; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 8).target; var currVal_2 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_4 = i0.ɵnov(_v, 14).target; var currVal_5 = i0.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_4, currVal_5); }); }
function View_LoginErrorModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Elektronski naslov, ki ste ga vnesli, ni napisan v pravilni obliki."])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Prosimo, ponovno vnesite elektronski naslov."]))], null, null); }
export function View_LoginErrorModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginErrorModalComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginErrorModalComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginErrorModalComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.error === 400); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.data.error === 401) && (_co.data.email.indexOf("@") > 0)); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.data.error === 401) && (_co.data.email.indexOf("@") === (0 - 1))); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_LoginErrorModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-login-error-modal", [], null, null, null, View_LoginErrorModalComponent_0, RenderType_LoginErrorModalComponent)), i0.ɵdid(1, 49152, null, 0, i3.LoginErrorModalComponent, [i1.ActivatedRoute], null, null)], null, null); }
var LoginErrorModalComponentNgFactory = i0.ɵccf("app-login-error-modal", i3.LoginErrorModalComponent, View_LoginErrorModalComponent_Host_0, {}, {}, []);
export { LoginErrorModalComponentNgFactory as LoginErrorModalComponentNgFactory };
