<div class="login-landing">
  <aside class="background-aside float-left col-lg-8 col-md-7 d-none d-md-block">

    <!-- <div class="content-wrap">
      <h1 i18n="@@landingTitle" class="pr-3">Enostavno in hitro do svojega izvajalca</h1>
      <p class="pr-3">Vse, kar potrebujete od svojega osebnega izvajalca, dostopno z <strong>le nekaj kliki preko varne spletne rešitve.</strong></p>
      <div class="row d-none d-lg-flex mt-5 pr-3">
        <div class="col-12 col-md-6">
          <h5><i class="icon las la-book-medical"></i> Zdravstvena dokumentacija in izvidi </h5>
          <p style="font-size: 1rem; opacity: 0.8;">
            Izvidi vaših pregledov in preiskav bodo dostopni le vam, pregledno urejeni in skrbno zaščiteni.</p>
        </div>

        <div class="col-12 col-md-6">
          <h5><i class="icon las la-file-prescription"></i> e-naročanje receptov, napotnic in bolniškega staleža </h5>
          <p style="font-size: 1rem; opacity: 0.8;">
            Naročilo storitev ni vezano na delovni čas ambulante in je podprto s samodejnim obveščanjem preko SMS ali e-pošte.</p>
        </div>
      </div>
      <button class="btn ewc-big-btn-infonet-reverse inner-width"
              type="button"
              (click)="openNewTab(doZdravnikaIntro)">
        Predstavitev <strong>doZdravnika</strong>
      </button> -->
      <!--<a href="{{doctorsUsingService}}" class="bottom-anchor pull-left text-white" target="_blank">
        <span i18n="@@landingVasZanima">Vas zanima, če vaš izvajalec uporablja spletno rešitev?</span>
        <i class="fas fa-external-link-square-alt ml-2"></i>
      </a>
    </div>-->
  </aside>
  <aside class="login-aside float-right col-lg-4 col-md-5 col-sm-12">
    <div class="content-wrap">
      <div class="aside-wrap col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <a routerLink="/">
          <svg height="76" width="300" class="d-block mx-auto landing-logo">
            <image xmlns:xlink="http://www.w3.org/1999/xlink"
                   xlink:href="assets/img/logo/doZdravnika.svg"
                   src="assets/img/logo/doZdravnika.png"
                   height="76"
                   width="100%">
            </image>
          </svg>
        </a>
        <app-login></app-login>
      </div>
      <div class="login-footer col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="help-block">
          <div class="mx-auto text-center support-text d-block text-nounderline">
            <!-- <div i18n="@@landingFooterLoginIssues" class="text-uppercase text-medium mb-2">
              Težave s prijavo?
            </div> -->

            <div class="divider">
              <span class="divider-line"></span>
              <span class="divider-content">
                <a routerLink="/help" class="py-1" routerLinkActive="active">
                  <u>Potrebujete pomoč?</u>
                </a>
              </span>
              <span class="divider-line"></span>
            </div>
            <!-- <u>Pogosta vprašanja</u></a> -->
            <!-- <a href="mailto:podpora@dozdravnika.si" class="py-1">
              <i class="las la-envelope"></i>
              podpora@dozdravnika.si
            </a>
            <a href="tel:+38646007600" class="py-1">
              <i class="las la-phone"></i>
              (04) 600-7600
            </a> -->
          </div>

        </div>
        <div class="row">
          <div class="col-6">
            <a href="{{aboutInfonet}}" target="_blank" i18n="@@landingFooterAbout">O nas</a>
          </div>

          
          <div class="col-6 ewc-anchor-text-color text-right">
            <!-- <a routerLink="/help" routerLinkActive="active">Pomoč</a> -->
          </div>
        </div>
        <div class="row login-footer-row ewc-push-up10">
          <div class="col-6 ewc-anchor-text-color">
            <a *ngIf="isMobile" href="{{doZdravnikaIntro}}" target="_blank" i18n="@@landingFooterIntroduction">Predstavitev</a>
            <a *ngIf="!isMobile" href="/gdpr">Obdelava podatkov</a>
          </div>
          <div class="col-6 text-right">
            <a href="{{eulaUrl}}" target="_blank" i18n="@@landingFooterTerms">Pravila in pogoji</a>
          </div>
        </div>
      </div>
    </div>
  </aside>
</div>
