/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./not-found.component";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "card card-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "p", [["class", "text-center"], ["id", "profile-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["class", "center-block landing-logo"], ["height", "76"], ["width", "300"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:image", [[":xlink:href", "assets/img/logo/doZdravnika.svg"], [":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["height", "76"], ["src", "assets/img/logo/doZdravnika.png"], ["width", "300"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h3", [["class", "text-center ewc-bold-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Stran ne obstaja"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "p", [["class", "text-center mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Nazaj na prvo stran"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.queryParams; var currVal_3 = "/"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_6 = "/"; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 11).target; var currVal_5 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_4, currVal_5); }); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotFoundComponent, [i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i1.ɵccf("app-not-found", i4.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
