import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { statuses } from "../../../constants";
import { User } from "../../../interfaces/user";
import { StorageService } from "../../../shared-services/storage.service";
import * as moment from "moment";
import { Reservation } from "../../../interfaces/reservation";
import { NgAddToCalendarService } from "@trademe/ng-add-to-calendar";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-reservation-modal",
  templateUrl: "./reservation-modal.component.html",
  styles: [],
})
export class ReservationModalComponent implements OnInit {
  @Input() reservation;
  public statuses: any = statuses;
  public user: User;

  constructor(
    public activeModal: NgbActiveModal,
    public storage: StorageService,
    private _addToCalendarService: NgAddToCalendarService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.user = this.storage.getUserData();
  }

  getGoogleCalendarUrl(reservation: Reservation) {
    return this._sanitizer.bypassSecurityTrustUrl(
      this._addToCalendarService.getHrefFor(
        this._addToCalendarService.calendarType.google,
        {
          // title: `Zdravniški pregled pri ${reservation.doctor.desc}`,
          title: `Zdravniški pregled`,
          start: moment(reservation.dateFrom).toDate(),
          duration: 30,
          address: `${reservation.orgName}`,
          // description: `Naročeni ste pri izvajalcu: ${reservation.doctor.desc}`,
          description: `Naročeni ste pri izvajalcu`,
        }
      )
    );
  }
}
