import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-recover-password-error-modal',
  template: `
    <span i18n="@@recoverPasswordToasterEmailNotRegistered">
    Uporabniški račun s tem e-naslovom ne obstaja.<br/><br/>
      Še niste registrirani?<br/></span><a routerLink="/account/register"><u
      i18n="@@recoverPasswordToasterRegister">Ustvarite si nov račun</u></a>
  `,
  styles: []
})
export class RecoverPasswordErrorModalComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
