/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../node_modules/angular2-toaster/src/toaster-container.component.ngfactory";
import * as i5 from "angular2-toaster/src/toaster-container.component";
import * as i6 from "angular2-toaster/src/toaster.service";
import * as i7 from "./app.component";
import * as i8 from "./modules/shared/login/login.service";
import * as i9 from "./shared-services/socket.service";
import * as i10 from "./shared-services/layout.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "navbar-opened": 0 }), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "toaster-container", [], null, null, null, i4.View_ToasterContainerComponent_0, i4.RenderType_ToasterContainerComponent)), i1.ɵdid(6, 245760, null, 0, i5.ToasterContainerComponent, [i6.ToasterService, i1.ChangeDetectorRef, i1.NgZone], { toasterconfig: [0, "toasterconfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, !_co.mainNavbarToggled); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = _co.toasterConfig; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i7.AppComponent, [i3.ActivatedRoute, i3.Router, i8.LoginService, i9.SocketService, i10.LayoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
