import * as tslib_1 from "tslib";
import { StompRService } from '@stomp/ng2-stompjs';
var SocketReservationsService = /** @class */ (function (_super) {
    tslib_1.__extends(SocketReservationsService, _super);
    function SocketReservationsService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SocketReservationsService;
}(StompRService));
export { SocketReservationsService };
