import { Component, OnInit, HostListener } from '@angular/core';
import {constants} from '../constants';
import {environment} from '../../environments/environment';
import * as _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public doctorsUsingService: string = constants.doctorsUsingService;
  public doZdravnikaIntro: string = constants.doZdravnikaIntro;
  public eulaUrl: string = constants.eulaUrl;
  public aboutInfonet: string = constants.aboutInfonet;
  public appVersion: string = environment.version;
  public helpUrl: string = constants.help;
  public isMobile: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    /*
    this.route.queryParams.subscribe((params: any) => {
      if (!_.isUndefined(params.doctorId) && !_.isUndefined(params.locations)) {
        if (!this.findChild('activate/:id', this.route)) {
          const redirectParams = JSON.stringify(params);
          this.router.navigate(['.'], {
            queryParams: {
              redirect: '/#!/app/reservation/schedule/:doctorId?locations',
              redirectParams: redirectParams,
            }
          });
        }
      }
    });*/
    this.isMobile = window.innerWidth < 768;
    console.log(this.appVersion);
  }

  openNewTab(url) {
    window.open(url, '_blank');
  }

  findChild(needle, route) {
    if (route && route.routeConfig && route.routeConfig.path === 'activate/:id') {
      return true;
    } else if (route.firstChild) {
      return this.findChild(needle, route.firstChild);
    } else {
      return false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 768;
  }
}
