<div class="modal-body text-center">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>

  <i class="fas fa-pills text-primary fa-5x mt-5 mb-3"></i>
  <p class="modal-card-text">
    <span class="text-primary d-block">{{prescription.doctorName}}</span>
    <span class="text-secondary">{{prescription.institutionName}}</span>
  </p>

  <div class="jumbotron p-3 mt-3 mb-3">
    Zdravila:
    <ul class="condensed-list text-left">
      <li *ngFor="let medication of prescription.medications" class="clearfix">
        <span class="condensed-list-bullet">{{medication.medicineQuantity}}&times;</span>
        <div class="d-inline-block float-left">
          {{medication.medicineName}}<br/>
          <div class="font-italic" *ngIf="medication.commentPatient">Komentar uporabnika: <strong>{{medication.commentPatient}}</strong></div>
          <div class="font-italic" *ngIf="medication.commentDoctor">Sporočilo ambulante: <strong>{{medication.commentDoctor}}</strong></div>
          <div class="font-italic" *ngIf="medication.rejectReason">Razlog zavrnitve: <strong>{{medication.rejectReason}}</strong></div>
        </div>
        <div class="status d-inline-block ml-1 float-left"
             [ngClass]="'status-'+statuses.prescriptionItems[medication.status].color"></div>

      </li>
    </ul>
  </div>

  <div class="mt-3 mb-3">
    <dl>

      <dt class="upper-case">Datum naročila:</dt>
      <dd>
        {{prescription.prescriptionDate | date : 'EEEE, d.M.yyyy \'ob\' HH:mm'}}
      </dd>

      <ng-container *ngIf="prescription.completedDate">
        <dt class="upper-case">Datum zaključka:</dt>
        <dd>
          {{prescription.completedDate | date : 'EEEE, d.M.yyyy \'ob\' HH:mm'}}
        </dd>
      </ng-container>

      <ng-container *ngIf="prescription.commentPatient">
        <dt class="upper-case">
          <small><strong>Komentar uporabnika:</strong></small>
        </dt>
        <dd>{{prescription.commentPatient}}</dd>
      </ng-container>

      <ng-container *ngIf="prescription.commentDoctor">
        <dt class="upper-case">
          <small><strong>Sporočilo ambulante:</strong></small>
        </dt>
        <dd>{{prescription.commentDoctor}}</dd>
      </ng-container>

      <ng-container *ngIf="prescription.rejectReason">
        <dt class="upper-case">
          <small><strong>Razlog zavrnitve:</strong></small>
        </dt>
        <dd>{{prescription.rejectReason}}</dd>
      </ng-container>

      <ng-container *ngIf="prescription.visitRequired">
        <dt class="upper-case">
          <small><strong></strong></small>
        </dt>
        <dd>Potreben je obisk v ambulanti</dd>
      </ng-container>

      <dt class="upper-case">
        <small><strong>Status:</strong></small>
      </dt>
      <dd>
        <div class="status" [ngClass]="'status-'+statuses.prescriptions[prescription.status].color">
          <span>{{statuses.prescriptions[prescription.status].name}}</span>
        </div>
      </dd>
    </dl>
    <div class="alert alert-danger" *ngIf="cancelingError">
      <strong>Prišlo je do napake</strong><br/>
      {{cancelingError}}
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="card-button btn btn-outline-danger btn-sm btn-reveal btn-round" *ngIf="!cancelingInProgress && prescription.status === 0"
          (click)="showModalCancelPrescription(prescription)"><i
    class="fa fa-times fa-fw"></i> <span>Prekliči naročilo</span></button>
  <div *ngIf="cancelingInProgress">Preklic naročila v obdelavi <i class="fa fa-spinner fa-spin"></i></div>
</div>
