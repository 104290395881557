import { Injectable, EventEmitter } from "@angular/core";

import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";

import * as _ from "lodash";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class UtilsService {
  public reloadData: EventEmitter<any> = new EventEmitter();
  public openUserHeader: boolean = false;
  public newUser;
  private alphabet = [
    "A",
    "B",
    "C",
    "Ć",
    "Č",
    "D",
    "Đ",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "Š",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "Ž",
  ];

  constructor(public jwtHelper: JwtHelperService, public http: HttpClient) {}

  generateGuid(): string {
    function r4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return (
      r4() +
      r4() +
      "-" +
      r4() +
      "-" +
      r4() +
      "-" +
      r4() +
      "-" +
      r4() +
      r4() +
      r4()
    );
  }

  toCamelCaseObject(rawObject: object): object {
    const newObject = {};

    _.forIn(rawObject, function (value, key) {
      const camelCaseKey = _.camelCase(key);
      newObject[camelCaseKey] = value;
    });

    return newObject;
  }

  parseUserData(token: { [s: string]: string }): object {
    const rawUserData = this.jwtHelper.decodeToken(token.accessToken);

    return this.toCamelCaseObject(rawUserData);
  }

  parseFullName(fullName: string): { firstName: string; lastName: string } {
    const fullNameSplitter = _.trim(fullName).split(" "),
      parsedName = {
        firstName: "",
        lastName: "",
      };

    if (fullNameSplitter.length > 1) {
      parsedName.firstName = _.first(fullNameSplitter);
      parsedName.lastName = _.trim(fullName.split(parsedName.firstName)[1]);
    } else if (fullNameSplitter.length === 1) {
      parsedName.firstName = _.first(fullNameSplitter);
    }

    return parsedName;
  }

  parseInitials(fullName: string): string {
    const splitted = this.parseFullName(fullName);
    return splitted.firstName.slice(0, 1) + splitted.lastName.slice(0, 1);
  }

  CharCompare(a, b, index) {
    if (a != undefined && b != undefined) {
      if (index == a.length || index == b.length) return 0;

      var aChar = this.alphabet.indexOf(a.toUpperCase().charAt(index));
      var bChar = this.alphabet.indexOf(b.toUpperCase().charAt(index));
      if (aChar != bChar) return aChar - bChar;
      else return this.CharCompare(a, b, index + 1);
    }
  }

  userListCleaner(tokenData) {
    if (tokenData) {
      let usersAllowed = tokenData["usersAllowed"];
      if (usersAllowed) {
        //usersAllowed = usersAllowed.filter(managingUser => managingUser.enabled);

        usersAllowed.sort((a, b) => {
          if (a.name !== undefined && b.name !== undefined) {
            return this.CharCompare(a.name, b.name, 0);
          }
        });

        tokenData["usersAllowed"] = usersAllowed;
      }
      return tokenData;
    }
    return "";
  }

  getHeader(token) {
    return {
      Authorization: "Bearer " + token.accessToken,
    };
  }

  getModuleNotification(data: any, module: string): string {
    let notification = "";
    if (!data) return "";

    if (data.modules) {
      let status =
        data.modules_validity &&
        data.modules_validity[module] &&
        data.modules_validity[module].active
          ? "enabled"
          : "disabled";

      if (module != "default")
        notification = this.getModuleNotification(data, "default");

      if (
        data.modules[module].notification &&
        data.modules[module].notification[status] &&
        data.modules[module].notification[status].text
      ) {
        notification = data.modules[module].notification[status].text;
      }

      let validity = data.modules[module].validity;
      if (validity.datetime && validity.datetime.status != null) {
        let datetime_validity =
          data.modules_validity && data.modules_validity[module].datetime_valid;
        notification = datetime_validity
          ? validity.datetime.text
            ? validity.datetime.text
            : notification
          : notification;
      }
    }

    if (data.external) {
      let ext_start = new Date(
        data.external.validity.from.date +
          "T" +
          data.external.validity.from.time
      );
      let ext_end = new Date(
        data.external.validity.to.date + "T" + data.external.validity.to.time
      );
      let now = new Date();
      console.log(ext_start < now && now < ext_end);
      if (
        ext_start < now &&
        now < ext_end &&
        !(
          data.modules_validity &&
          data.modules_validity[module] &&
          data.modules_validity[module].active
        ) &&
        data.external.notification
      )
        notification = data.external.notification;
    }

    return notification;
  }

  getJSONValidation(json: object) {
    let formData = new FormData();
    formData.append("json", JSON.stringify(json));

    return this.http.post(
      "https://app.infonet.si/x21/services/validity/json/",
      formData
    );
  }

  sanitizeFilename(filename) {
    return filename.replace(/\x00-\x1F/g, "").replace(/[<>:"\/\\|?*]/g, "-");
  }

  getServerUrl(): string {
    return (
      environment.apiServer + ":" + environment.apiPort + environment.apiPath
    );
  }

  getDocumentsUrl(): string {
    return (
      environment.documentsServer +
      ":" +
      environment.documentsPort +
      environment.documentsPath
    );
  }

  getAuthUrl(): string {
    return (
      environment.authServer +
      ":" +
      environment.authApiPort +
      environment.authApiPath
    );
  }

  getPublicServerUrl(): string {
    return (
      environment.apiServer +
      ":" +
      environment.publicApiPort +
      environment.publicApiPath
    );
  }

  getModulesServerUrl(): string {
    return (
      environment.apiServer +
      ":" +
      environment.publicApiPort +
      environment.modulesApiPath
    );
  }

  getSettingsServerUrl(): string {
    return (
      environment.apiServer +
      ":" +
      environment.publicApiPort +
      environment.settingsApiPath
    );
  }

  getSocketUrl(): string {
    return (
      environment.apiServer + ":" + environment.apiPort + environment.socketPath
    );
  }

  getTvwUrl(): string {
    return environment.tvwUrl;
  }

  getPrescriptionsUrl(): string {
    return (
      environment.prescriptionsServer +
      ":" +
      environment.prescriptionsPort +
      environment.prescriptionsPath
    );
  }

  getMessagesUrl(): string {
    return (
      environment.messagesServer +
      ":" +
      environment.messagesPort +
      environment.messagesPath
    );
  }

  getRelativesApiPath(): string {
    return (
      environment.apiServer +
      ":" +
      environment.publicApiPort +
      environment.relativesApiPath
    );
  }

  // getStaticConfig(): Observable<any> {
  //   return this.http.get(environment.staticConfig).pipe(
  //     catchError((error) => {
  //       return of({});
  //     })
  //   );
  // }

  allowQuestion(questions, question) {
    const condition = questions.find(
      (q) => q.code == question.condition.question
    );
    if (!question.condition.question) return true;

    if (condition && condition.answer) {
      if (
        condition.answer.type == "number" ||
        condition.answer.type == "date"
      ) {
        switch (question.condition.compare) {
          case "<":
            return condition.answer.input < question.condition.value;
          case ">":
            return condition.answer.input > question.condition.value;

          case "=":
          default:
            return question.condition.value == condition.answer.input;
        }
      } else if (condition.answer.type == "checkbox") {
        const option = condition.answer.options.find(
          (opt) => opt.value == question.condition.value
        );
        return option.input;
      } else {
        return question.condition.value == condition.answer.input;
      }
    } else return false;
  }

  isInvalidAnswer(questions, q) {
    q.error = {
      error_found: false,
      minimum: false,
      maximum: false,
      invalid: false,
      required: false,
      empty: false,
    };

    let isAllowed = this.allowQuestion(questions, q);
    if (!isAllowed) return;

    if (q.answer.type == "checkbox") {
      const isSelectedOption = q.answer.options.find(
        (opt) => opt.input == true
      );
      if (isSelectedOption) return;

      q.error.required = true;
      q.error.empty = true;
      q.error.error_found = true;
      return;
    }

    if (q.required && !q.answer.input) {
      q.error.required = true;
      q.error.empty = true;
      q.error.error_found = true;
      return;
    }

    let min = q.answer.min;
    let max = q.answer.max;

    if (min || max) {
      switch (q.answer.type) {
        case "text":
          q.error.minimum =
            min != "" ? q.answer.input.length < Number(min) : false;
          q.error.maximum =
            max != "" ? q.answer.input.length > Number(max) : false;
          break;
        case "number":
          q.error.minimum = min != "" ? q.answer.input < Number(min) : false;
          q.error.maximum = max != "" ? q.answer.input > Number(max) : false;
          break;
        case "date":
          if (q.answer.input) {
            let range = this.calculatePeriod(q);

            if (
              q.answer.date_type == "today" ||
              q.answer.date_type == "fixed"
            ) {
              q.error.minimum =
                min != ""
                  ? q.answer.input.setHours(0, 0, 0, 0) < range.min
                  : false;
              q.error.maximum =
                max != ""
                  ? q.answer.input.setHours(0, 0, 0, 0) > range.max
                  : false;
            } else {
              let today = new Date();
              today.setHours(0, 0, 0, 0);

              let inputRange = this.calculatePeriod(q, q.answer.input);

              q.error.minimum = min != "" ? today < inputRange.min : false;
              q.error.maximum = max != "" ? today > inputRange.max : false;
            }
          }
          q.error.invalid = q.answer.input == "Invalid Date";
          break;
        default:
          break;
      }
    }

    q.error.error_found =
      q.error.error_found ||
      q.error.minimum ||
      q.error.maximum ||
      q.error.invalid ||
      (q.error.required && q.error.empty);

    if (!q.error.required && !!q.answer.input)
      q.error.error_found =
        q.error.error_found && !q.error.required && !!q.answer.input;
  }

  calculatePeriod(data, alternateDate?) {
    let resultDate = alternateDate ? alternateDate : new Date();
    if (data.answer.date_type == "today") {
    } else if (data.answer.date_type == "fixed") {
      resultDate = new Date(data.answer.fixed_date);
    } else if (data.answer.date_type == "input") {
      resultDate = data.answer.input;
    }

    resultDate.setHours(0, 0, 0, 0);

    let min = new Date(resultDate);
    min.setDate(resultDate.getDate() - data.answer.min);

    let max = new Date(resultDate);
    max.setDate(resultDate.getDate() + data.answer.max);

    return {
      min: data.answer.min ? min : undefined,
      max: data.answer.max ? max : undefined,
    };
  }
}
