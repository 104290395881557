import * as tslib_1 from "tslib";
import { StompRService } from '@stomp/ng2-stompjs';
var SocketPrescriptionsService = /** @class */ (function (_super) {
    tslib_1.__extends(SocketPrescriptionsService, _super);
    function SocketPrescriptionsService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SocketPrescriptionsService;
}(StompRService));
export { SocketPrescriptionsService };
