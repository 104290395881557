import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../interfaces/user';
import {statuses} from '../../../constants';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '../../../shared-services/storage.service';
import {PrescriptionsService} from '../../../shared-services/prescriptions.service';
import {ToasterService} from 'angular2-toaster';
import swal from 'sweetalert2';

@Component({
  selector: 'app-prescription-modal',
  templateUrl: './prescription-modal.component.html',
  styles: []
})
export class PrescriptionModalComponent implements OnInit {

  @Input() prescription;
  public statuses: any = statuses;
  public user: User;

  public cancelingError;
  public cancelingInProgress = false;

  constructor(
    public activeModal: NgbActiveModal,
    public storage: StorageService,
    private prescriptionsService: PrescriptionsService,
    private toaster: ToasterService,
  ) {
  }

  ngOnInit() {
    this.user = this.storage.getUserData();
  }

  showModalCancelPrescription(prescription) {
    this.cancelingInProgress = true;
    this.prescriptionsService.cancelPrescription(prescription.id, 'Test')
      .subscribe((response) => {
        /* this.toaster.pop({
          type: 'success',
          body: 'Naročilo je bilo uspešno preklicano.',
        }); */

        swal({
          title: 'Naročilo preklicano',
          html: `Naročilo je bilo uspešno preklicano.`,
          type: 'success',
          confirmButtonText: 'Zapri',
          confirmButtonClass: 'btn btn-info btn-pill',
          showCloseButton: true
        });

        this.cancelingInProgress = false;

        this.activeModal.dismiss('Canceled');
      }, (error) => {
        this.cancelingError = error.error.message;
        this.cancelingInProgress = false;
      });
  }

}
