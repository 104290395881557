import { ActivatedRoute } from '@angular/router';
var LoginErrorModalComponent = /** @class */ (function () {
    function LoginErrorModalComponent(route) {
        var _this = this;
        this.route = route;
        this.route.queryParams.subscribe(function (params) {
            _this.queryParams = params;
        });
    }
    Object.defineProperty(LoginErrorModalComponent.prototype, "data", {
        get: function () {
            return this.toast.data;
        },
        enumerable: true,
        configurable: true
    });
    return LoginErrorModalComponent;
}());
export { LoginErrorModalComponent };
