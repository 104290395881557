import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-registration-error-modal',
  template: `
    <span i18n="@@registrationErrorToasterEmailKZZAlreadyRegistered">
    Račun s tem elektronskim naslovom ali ZZZS številko že obstaja!<br/><br/>
      Ste pozabili svoje geslo?</span> <a routerLink="/account/password"><u
      i18n="@@registrationErrorToasterRequestPassword">Zahtevajte novo geslo</u></a><span
      i18n="@@registrationErrorToasterAlreadyRegisteredQuestion"><br/><br/>
      Že imate uporabniški račun?</span> <a routerLink="/"><u
      i18n="@@registrationErrorToasterBackToLogin">Vrnite se na prijavo</u></a>
  `,
  styles: []
})
export class RegistrationErrorModalComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
