import { UserSelectionComponent } from './header/user-selection/user-selection.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { OutsideClickDirective } from './../../shared-services/outsideClick.directive';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './footer/footer.component';
import {ZzzsDirective} from './validators/zzzs.directive';
import {PasswordMatchDirective} from './validators/password-match.directive';
import {LoaderComponent} from './loader/loader.component';
import {UserAvatarComponent} from './user-avatar/user-avatar.component';
import {ProgressStepsComponent} from './progress-steps/progress-steps.component';
import {ReservationModalComponent} from './reservation-modal/reservation-modal.component';
import {PrescriptionModalComponent} from './prescription-modal/prescription-modal.component';
import {BsDatepickerModule, TypeaheadModule} from 'ngx-bootstrap';
import {NgAddToCalendarModule} from '@trademe/ng-add-to-calendar';
import { BoldPipePipe } from './pipes/bold-pipe.pipe';
import { LoginComponent } from './login/login.component';
import { LoginErrorModalComponent } from './login/login-error-modal.component';
import { LoginService } from './login/login.service';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgbModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    RouterModule,
    NgAddToCalendarModule,
  ],
  declarations: [
    LoginComponent,
    LoginErrorModalComponent,
    HeaderComponent,
    UserSelectionComponent,
    FooterComponent,
    PasswordMatchDirective,
    ZzzsDirective,
    OutsideClickDirective,
    LoaderComponent,
    UserAvatarComponent,
    ProgressStepsComponent,
    ReservationModalComponent,
    PrescriptionModalComponent,
    PasswordStrengthComponent,
    BoldPipePipe
  ],
  exports: [
    LoginComponent,
    LoginErrorModalComponent,
    HeaderComponent,
    UserSelectionComponent,
    FooterComponent,
    CommonModule,
    FormsModule,
    PasswordMatchDirective,
    ZzzsDirective,
    OutsideClickDirective,
    LoaderComponent,
    UserAvatarComponent,
    ProgressStepsComponent,
    ReservationModalComponent,
    PrescriptionModalComponent,
    PasswordStrengthComponent,
    BoldPipePipe,
  ],
  providers: [
    LoginService, 
  ],
  entryComponents: [ReservationModalComponent, PrescriptionModalComponent],
})
export class SharedModule {
}
