/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./recover-password-error-modal.component";
var styles_RecoverPasswordErrorModalComponent = [];
var RenderType_RecoverPasswordErrorModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RecoverPasswordErrorModalComponent, data: {} });
export { RenderType_RecoverPasswordErrorModalComponent as RenderType_RecoverPasswordErrorModalComponent };
export function View_RecoverPasswordErrorModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Uporabni\u0161ki ra\u010Dun s tem e-naslovom ne obstaja."])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u0160e niste registrirani?"])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "a", [["routerLink", "/account/register"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ustvarite si nov ra\u010Dun"]))], function (_ck, _v) { var currVal_2 = "/account/register"; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 7).target; var currVal_1 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_RecoverPasswordErrorModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-recover-password-error-modal", [], null, null, null, View_RecoverPasswordErrorModalComponent_0, RenderType_RecoverPasswordErrorModalComponent)), i0.ɵdid(1, 114688, null, 0, i3.RecoverPasswordErrorModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecoverPasswordErrorModalComponentNgFactory = i0.ɵccf("app-recover-password-error-modal", i3.RecoverPasswordErrorModalComponent, View_RecoverPasswordErrorModalComponent_Host_0, {}, {}, []);
export { RecoverPasswordErrorModalComponentNgFactory as RecoverPasswordErrorModalComponentNgFactory };
