/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./registration-error-modal.component";
var styles_RegistrationErrorModalComponent = [];
var RenderType_RegistrationErrorModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegistrationErrorModalComponent, data: {} });
export { RenderType_RegistrationErrorModalComponent as RenderType_RegistrationErrorModalComponent };
export function View_RegistrationErrorModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Ra\u010Dun s tem elektronskim naslovom ali ZZZS \u0161tevilko \u017Ee obstaja!"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Ste pozabili svoje geslo?"])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "a", [["routerLink", "/account/password"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Zahtevajte novo geslo"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u017De imate uporabni\u0161ki ra\u010Dun?"])), (_l()(), i0.ɵeld(13, 0, null, null, 3, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Vrnite se na prijavo"]))], function (_ck, _v) { var currVal_2 = "/account/password"; _ck(_v, 6, 0, currVal_2); var currVal_5 = "/"; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).target; var currVal_1 = i0.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 14).target; var currVal_4 = i0.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_3, currVal_4); }); }
export function View_RegistrationErrorModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-registration-error-modal", [], null, null, null, View_RegistrationErrorModalComponent_0, RenderType_RegistrationErrorModalComponent)), i0.ɵdid(1, 114688, null, 0, i3.RegistrationErrorModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistrationErrorModalComponentNgFactory = i0.ɵccf("app-registration-error-modal", i3.RegistrationErrorModalComponent, View_RegistrationErrorModalComponent_Host_0, {}, {}, []);
export { RegistrationErrorModalComponentNgFactory as RegistrationErrorModalComponentNgFactory };
