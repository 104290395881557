export const constants = Object.freeze({
  doctorsUsingService: "/predstavitev/#spisek",
  doZdravnikaIntro: "/predstavitev/",
  eulaUrl: "/terms/",
  aboutInfonet: "/predstavitev/#podjetje",
  help: "/pomoc/",
});

export const apples = Object.freeze([
  {
    type: "generic",
    title: "Verificiran uporabniški račun",
    content: `Pri naslednjem obisku svojega izvajalca zahtevajte verifikacijo svojega računa.`,
    image: "/assets/img/jabolko-na-dan.svg",
  },
  {
    type: "generic",
    title: "Verificiran uporabniški račun",
    content: `Z verificiranim uporabniškim računom pridobite dostop do naprednih
      funkcionalnosti spletne rešitve doZdravnika.`,
    image: "/assets/img/jabolko-na-dan.svg",
  },
  {
    type: "reservations",
    title: "Naročanje na termine",
    content:
      "S spletno rešitvijo doZdravnika se lahko naročite na termin pri svojem izbranem izvajalcu.",
    image: "/assets/img/jabolko-na-dan.svg",
  },
  {
    type: "prescriptions",
    title: "Naročanje receptov",
    content:
      "S spletno rešitvijo doZdravnika lahko naročite nov recept za svoja obstoječa zdravila.",
    image: "/assets/img/jabolko-na-dan.svg",
  },
  {
    type: "documents",
    title: "Prejemanje dokumentov",
    content:
      "V spletno rešitvijo doZdravnika lahko prejemate različne dokumente.",
    image: "/assets/img/jabolko-na-dan.svg",
  },
  {
    type: "referrals",
    title: "Naročanje napotnic",
    content:
      "S spletno rešitvijo doZdravnika lahko naročite napotnico za obisk specialista.",
    image: "/assets/img/jabolko-na-dan.svg",
  },
  {
    type: "sickLists",
    title: "Naročanje bolniških staležev",
    content: "S spletno rešitvijo doZdravnika lahko naročite bolniški stalež.",
    image: "/assets/img/jabolko-na-dan.svg",
  },
  {
    type: "messages",
    title: "Komunikacija z izvajalcem",
    content:
      "S spletno rešitvijo doZdravnika lahko izbranemu izvajalcu pošljete sporočilo.",
    image: "/assets/img/jabolko-na-dan.svg",
  },
]);

export const statuses = Object.freeze({
  reservations: {
    "-1": {
      name: "Neveljaven status",
      color: "",
    },
    1: {
      name: "Aktiven termin",
      color: "success",
    },
    2: {
      name: "Termin ste preklicali",
      color: "warning",
    },
    3: {
      name: "Ustanova je zavrnila termin",
      color: "warning",
    },
    4: {
      name: "Termin je bil izveden",
      color: "info",
    },
    5: {
      name: "Pretekel termin",
      color: "danger",
    },
  },
  referrals: {
    "-1": {
      name: "Neveljaven status",
      color: "",
    },
    0: {
      name: "Naročilo napotnice oddano",
      color: "info",
    },
    1: {
      name: "Naročilo napotnice preklicano",
      color: "warning",
    },
    2: {
      name: "Naročilo napotnice v obdelavi",
      color: "info",
    },
    3: {
      name: "Naročilo napotnice je bilo zavrnjeno",
      color: "danger",
    },
    4: {
      name: "Naročilo napotnice je bilo potrjeno",
      color: "success",
    },
  },
  sickLists: {
    "-1": {
      name: "Neveljaven status",
      color: "",
    },
    0: {
      name: "Naročilo bolniškega staleža oddano",
      color: "info",
    },
    1: {
      name: "Naročilo bolniškega staleža preklicano",
      color: "warning",
    },
    2: {
      name: "Naročilo bolniškega staleža v obdelavi",
      color: "info",
    },
    3: {
      name: "Naročilo bolniškega staleža je bilo zavrnjeno",
      color: "danger",
    },
    4: {
      name: "Naročilo bolniškega staleža je bilo potrjeno",
      color: "success",
    },
  },
  prescriptions: {
    "-1": {
      name: "Neveljaven status",
      color: "",
    },
    0: {
      name: "Naročilo recepta oddano",
      color: "info",
    },
    1: {
      name: "Naročilo recepta preklicano",
      color: "warning",
    },
    2: {
      name: "Naročilo recepta v obdelavi",
      color: "info",
    },
    3: {
      name: "Naročilo recepta je bilo zavrnjeno",
      color: "danger",
    },
    4: {
      name: "Naročilo recepta je bilo potrjeno",
      color: "success",
    },
    5: {
      name: "Potreben je obisk pri izvajalcu",
      color: "danger",
    },
  },

  prescriptionItems: {
    "-1": {
      name: "Neveljaven status",
      color: "",
    },
    0: {
      name: "Čaka na obdelavo",
      color: "info",
    },
    1: {
      name: "Zdravilo je bilo preklicano",
      color: "warning",
    },
    3: {
      name: "Zdravilo je bilo zavrnjeno",
      color: "danger",
    },
    4: {
      name: "Zdravilo je bilo potrjeno",
      color: "success",
    },
    5: {
      name: "Zdravilo je bilo zamenjano",
      color: "success",
    },
    6: {
      name: "Zdravilo je bilo naknadno dodano",
      color: "success",
    },
  },

  messages: {
    "-1": {
      name: "Neveljaven status",
      color: "",
    },
    1: {
      name: "Neprebrano",
      color: "info",
    },
    2: {
      name: "Prebrano",
      color: "success",
    },
    3: {
      name: "Zaključeno z odgovorom",
      color: "success",
    },
    4: {
      name: "Zaključeno brez odgovora",
      color: "danger",
    },
    5: {
      name: "Zaključeno",
      color: "success",
    },
    99: {
      name: "Poslano",
      color: "success",
    },
  },

  documents: {
    "-1": {
      name: "Neveljaven status",
      color: "",
    },
    1: {
      name: "Dokument",
      color: "info",
    },
    4: {
      name: "Delno izpolnjen",
      color: "warning",
    },
    7: {
      name: "Zaključen",
      color: "success",
    },
    byType: {
      1: {
        1: {
          name: "Dokument",
          color: "info",
        },
        4: {
          name: "Delno izpolnjen",
          color: "warning",
        },
        7: {
          name: "Zaključen",
          color: "success",
        },
      },
      2: {
        1: {
          name: "Dokument",
          color: "info",
        },
        4: {
          name: "Delno izpolnjen",
          color: "warning",
        },
        7: {
          name: "Podpisan",
          color: "success",
        },
      },
      3: {
        1: {
          name: "Dokument",
          color: "info",
        },
        4: {
          name: "/",
          color: "warning",
        },
        7: {
          name: "Ogledan",
          color: "success",
        },
      },
      4: {
        1: {
          name: "Dokument",
          color: "info",
        },
      },
    },
  },
});
