import {Injectable} from '@angular/core';
import {SocketProviderService} from './socket-provider.service';
import {StompConfig, StompRService} from '@stomp/ng2-stompjs';

import {Message} from '@stomp/stompjs';
import {StorageService} from './storage.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {CommandHeader} from '../interfaces/command-header';
import {CommandMessage} from '../interfaces/command-message';
import {User} from '../interfaces/user';
import {ResultMessage} from '../interfaces/result-message';
import {Subject} from 'rxjs/Subject';
import {Order} from '../interfaces/order';
import {Medication} from '../interfaces/medication';
import {Reservation} from '../interfaces/reservation';

import * as _ from 'lodash';
import {finalize, share} from 'rxjs/operators';
import {Activity} from '../interfaces/activity';
import {SocketReservationsService} from './socket-reservations.service';
import {SocketPrescriptionsService} from './socket-prescriptions.service';
import {Doctor} from '../interfaces/doctor';
import { LoginService } from '../modules/shared/login/login.service';

@Injectable()
export class SocketService {

  private commandObservables: Subject<any>[] = [];

  private stompConfig: StompConfig = {
    url: '',
    headers: {
      username: 'guest',
      password: 'guest'
    },
    heartbeat_in: 0,
    heartbeat_out: 20000,
    reconnect_delay: 5000,
    debug: !environment.production
  };

  private stompPrescriptionsConfig: StompConfig = {
    url: '',
    headers: {
      username: 'guest',
      password: 'guest'
    },
    heartbeat_in: 0,
    heartbeat_out: 20000,
    reconnect_delay: 5000,
    debug: !environment.production
  };

  constructor(
    private socketProvider: SocketProviderService,
    private socketPrescriptionsProvider: SocketProviderService,
    private stompService: SocketReservationsService,
    private prescriptionsStompService: SocketPrescriptionsService,
    private storage: StorageService,
  ) {
  }

  initSocket(): void {
    this.initDefaultSocket();
    this.initPrescriptionsSocket();
  }

  initDefaultSocket(): void {
    const urlProvider = this.socketProvider.getProvider();
    if (urlProvider) {
      this.stompConfig.url = urlProvider;
      this.stompService.config = this.stompConfig;
      this.stompService.initAndConnect();

      // let timeout = 20;
      // let checkConnection = setInterval(()=>{
      //   if(this.stompService.connected() || timeout == 0){
      //     clearInterval(checkConnection);
      //     this.storage.clearAll();
      //     this.disconnect();
      //   }
      //   else {
      //     timeout--;
      //   }
      // }, 100);
      // this.initSubscription('', this.stompService);
    }
  }

  initPrescriptionsSocket(): void {
    const urlProvider = this.socketPrescriptionsProvider.getProvider({
      socketServer: environment.prescriptionsSocketServer,
      socketPort: environment.prescriptionsSocketPort,
      socketPath: environment.prescriptionsSocketPath,
      socketToken: environment.prescriptionsSocketToken,
    });
    if (urlProvider) {
      this.stompPrescriptionsConfig.url = urlProvider;
      this.prescriptionsStompService.config = this.stompPrescriptionsConfig;
      this.prescriptionsStompService.initAndConnect();

      // let timeout = 20;
      // let checkConnection = setInterval(()=>{
      //   console.log(this.prescriptionsStompService.connected());
      //   if(this.prescriptionsStompService.connected() || timeout == 0){
      //     clearInterval(checkConnection);
      //     this.storage.clearAll();
      //     this.disconnect();
      //   }
      //   else {
      //     timeout--;
      //   }
      // }, 100);
      // this.initSubscription('', this.prescriptionsStompService);
    }
  }

  isConnected(){
    return this.prescriptionsStompService.connected() && this.stompService.connected();
  }

  initSubscription(hash = '', connection: any): void | Observable<any> {
    let guid = this.storage.getUserGUID();
    if (hash) {
      guid += '-' + hash;
    }
    const stompSubscription = connection
      .subscribe('/queue/' + guid)
      .map((message: Message) => {
        let msg = JSON.parse(message.body);
        msg = msg[0];
        if (msg.payload) {
          let decoded = msg.payload;
          try {
            decoded = decodeURI(msg.payload);
          } catch (e) {
            console.log(e);
          }
          msg.payload = JSON.parse(decoded);
        }
        return msg;
      });
    if (hash) {
      return stompSubscription;
    } else {
      stompSubscription.subscribe((results: ResultMessage) => {
        let commandName = results.resultHeader.commandName;
        if (hash) {
          commandName += '-' + hash;
        }
        if (this.commandObservables.hasOwnProperty(commandName)) {
          this.commandObservables[commandName].next(results);
        }
      });
    }
  }

  disconnect(): void {
    this.stompService.disconnect();
    this.prescriptionsStompService.disconnect();
  }

  get(commandName: string, hash = ''): Observable<any> {
    if (hash) {
      commandName += '-' + hash;
    }

    if (!this.commandObservables.hasOwnProperty(commandName)) {
      const newObservable = new Subject<any>();
      newObservable.pipe(
        finalize(() => {
        }),
        share()
      );
      this.commandObservables[commandName] = newObservable;
    }
    return this.commandObservables[commandName];
  }

  request(commandName: string, message?: object, header?: CommandHeader, path?: string,
          isolate = false, connection?): Observable<any> {

    if (!connection) {
      connection = this.stompService;
    }

    let hash, subscription;
    if (isolate) {
      hash = new Date().getTime().toString();
      subscription = this.initSubscription(hash, connection);
    } else {
      subscription = this.get(commandName, hash);
    }

    if (message == null) {
      message = {};
    }

    this.sendMessage(
      connection,
      commandName,
      commandName,
      message,
      header,
      path,
      null,
      hash,
    );

    return subscription;
  }

  sendMessage(connection, commandId: string, commandName: string, message?: object, header?: CommandHeader,
              path?: string,
              user?: User, hash = ''): void {
    if (message == null) {
      message = {};
    }

    let sessionId = this.storage.getUserGUID();
    if (hash) {
      sessionId += '-' + hash;
    }

    let commandHeader: CommandHeader = {
      commandId: commandId,
      commandName: commandName,
      sessionId: sessionId,
      destinations: [],
      user: user ? user : this.storage.getUserData(),
      cmdTime: Date.now()
    };

    if (header) {
      commandHeader = _.merge(commandHeader, header);
    }


    const payload = JSON.stringify(message);

    const commandMessage: CommandMessage = {
      commandHeader: commandHeader,
      payload: payload
    };

    let pathString = '/app';
    if (path) {
      pathString += '/' + path;
    }
    connection.publish(pathString, JSON.stringify(commandMessage));
  }

  requestOrders(): Observable<Order[]> {
    return this.request('getOrders');
  }

  getDoctorFreeSlots(doctorId: string, locations: string | any[], activity: Activity, dateFrom, appId): Observable<any[]> {
    if (typeof locations === 'string') {
      locations = [locations];
    }

    const message: any = {
      dateFrom: dateFrom,
    }

    if (doctorId && (!activity || !activity.vzsId)) {
      message.doctorId = doctorId;
    }

    if (activity) {
      if (activity.vzsId) {
        message.vzs = activity.vzsId;
      } else if(activity.id) {
        message.activityCode = activity.id;
      }
      else if(typeof activity == 'string') {
        message.activityCode = activity;
      }
    }

    if(appId)
      message.appId = appId;

    return this.request('getDoctorFreeSlots', message, {
      destinations: locations
    }, 'getDoctorFreeSlots', true);
  }

  getDoctorfirstFreeSlots(doctorId: string, locations: string | any[], activity: string, dateFrom, appId): Observable<any[]> {
    if (typeof locations === 'string') {
      locations = [locations];
    }
    return this.request('getDoctorFirstFreeSlots', {
      doctorId: doctorId,
      dateFrom: dateFrom,
      activityCode: activity,
      appId: appId
    }, {
      destinations: locations
    }, 'getDoctorFirstFreeSlots', true);
  }

  createReservation(location, scheduleId, dateFrom, unitId, doctorId, activity?: Activity, duration = undefined) {

    let message: any = {
      scheduleId: scheduleId,
      dateFrom: dateFrom,
      unitId: unitId,
      doctorId: doctorId,
      // reservationForPerson: reservationForPerson
    };

    if (activity) {
      if (activity.vzsId) {
        message = {
          ...{
            vzs: activity.vzsId,
            isVideoCall: activity.isVideoCall ? activity.isVideoCall : false
          }, ...message
        };
      } else {
        message = {
          ...{
            selectedActivityCode: activity.id,
            selectedActivityDescription: activity.desc,
            isVideoCall: activity.isVideoCall ? activity.isVideoCall : false
          }, ...message
        };
      }
    }

    if(duration){
      message = {
        ...{
          reservationDuration: duration
        }, ...message
      };
      
    }

    return this.request('createReservation',
      message,
      {
        destinations: [location]
      },
      'createReservation',
      true
    );
  }

  confirmReservation(prereservationId, location, comment = '', documentIds = []) {
    return this.request('confirmReservation',
      {
        prereservationId: prereservationId,
        comment: comment,
        documentIds: documentIds
      },
      {
        destinations: [location]
      },
      'confirmReservation',
      true
    );
  }

  createWaitingList(doctor: Doctor, unit, activity: Activity, location, comment = '') {
    return this.request('waitingListCreated',
      {
        doctorId: null,
        doctorDesc: null,
        unitId: null,
        unitDesc: null,
        selectedActivityCode: activity.id,
        selectedActivityDescription: activity.desc,
        comment: comment
      },
      {
        destinations: [location]
      },
      'waitingListCreated',
      true
    );
  }

  cancelReservation(message: Reservation, header?: CommandHeader): Observable<Reservation[]> {
    return this.request('cancelReservation', message, header, 'cancelReservation', true);
  }

  requestMedications(locations: string | any[], user: object | User,
                     doctorId): Observable<{ medications: Medication[] }> {
    if (typeof locations === 'string') {
      locations = [locations];
    }
    return this.request('getMedicationsForPatient', {
        user: user,
        doctorId: doctorId,
      },
      {
        destinations: locations,

      }, 'getMedicationsForPatient', true, this.prescriptionsStompService
    );
  }
}
