import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var LayoutService = /** @class */ (function () {
    function LayoutService() {
        this.navbarToggle = false;
        this.navbarToggle$ = new BehaviorSubject(this.navbarToggle);
    }
    LayoutService.prototype.toggleNavbar = function (whichWay) {
        if (whichWay === void 0) { whichWay = null; }
        if (whichWay === null) {
            whichWay = !this.navbarToggle;
        }
        this.navbarToggle = whichWay;
        this.navbarToggle$.next(this.navbarToggle);
    };
    LayoutService.ngInjectableDef = i0.defineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(); }, token: LayoutService, providedIn: "root" });
    return LayoutService;
}());
export { LayoutService };
